import * as React from 'react';
import styled from 'styled-components';
import { keyFromString } from '../../utils/strings';
import theme from '../../styles/theme';
import { ProfileImage } from '../../styles/reusableComponents';
import { IImageField } from '../../utils/types';
import GatsbyImage from 'gatsby-image';
import { medias } from '../../styles/helpers/mediaQueries';

export interface ITestimonialData {
  body: string;
  name: string;
  title?: string;
  image?: IImageField;
}

interface ITestimonialProps extends ITestimonialData {
  isHighlighted?: boolean;
  index?: number;
  changeActive?: (i: number) => any;
}

interface IWrapperProps {
  isHighlighted: boolean;
  index: number;
}

const Wrapper = styled.div`
margin: 2.5rem auto;
outline: none;
  svg {
    width: 2rem;
  }
  p {
    font-size: 0.8rem;
    margin: 1.5em 0;
    :first-of-type {
      margin-top: 0;
    }
    :last-of-type {
      margin-bottom: 1rem;
    }
  }
  @media ${medias.tabletMin} {
    margin: 0;
    opacity: ${({ isHighlighted }: IWrapperProps) => (isHighlighted ? 1 : 0.2)};
  transition: ${theme.transitions.default};
  /* order: ${({ isHighlighted, index }: IWrapperProps) =>
    isHighlighted ? 2 : index}; */
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  h4 {
    font-weight: 300;
    margin: 0.25rem 0 0;
    font-size: 0.8rem;
  }
  /* img {
    max-width: 70px !important;
  } */
  > ${ProfileImage} {
    margin-right: 0.75rem;
    flex: 5 0 40px;
    max-width: 50px;
    @media ${medias.tabletMin} {
      max-width: 70px;
      img,
      .gatsby-image-wrapper {
        max-width: 70px;
      }
    }
  }
`;

export const Testimonial: React.SFC<ITestimonialProps> = props => {
  const { isHighlighted = true, index = 0 } = props;
  const imgAlt = `Foto de ${props.name}`;
  const activate = () => {
    if (props.changeActive) {
      props.changeActive(index);
    }
  };
  return (
    <Wrapper
      isHighlighted={isHighlighted}
      index={index}
      onMouseEnter={activate}
      tabIndex={0}
      onFocus={activate}
    >
      <svg
        viewBox="0 0 35 29"
        fill={theme.colors.primary}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.6329 14.3035L34.6329 0.193822L20.5233 0.193821L20.5233 14.3035L28.5859 14.3035C28.5859 18.756 24.9758 22.3661 20.5233 22.3661L20.5233 28.4131C28.3165 28.4131 34.6329 22.0963 34.6329 14.3035ZM0.367052 22.3661L0.367051 28.4131C8.1599 28.4131 14.4767 22.0963 14.4767 14.3035L14.4767 0.19382L0.367053 0.193819L0.367052 14.3035L8.4297 14.3035C8.4297 18.756 4.8196 22.3661 0.367052 22.3661Z"
        />
      </svg>
      {props.body.split('\n').map(p => (
        <p key={keyFromString(p, 14)}>{p}</p>
      ))}
      <AuthorWrapper>
        {props.image && props.image.asset && props.image.asset.fluid && (
          <ProfileImage>
            <GatsbyImage fluid={props.image.asset.fluid} alt={imgAlt} />
          </ProfileImage>
        )}
        <div style={{ flex: 1 }}>
          <h3>{props.name}</h3>
          {typeof props.title === 'string' ? <h4>{props.title}</h4> : null}
        </div>
      </AuthorWrapper>
    </Wrapper>
  );
};

Testimonial.displayName = 'Testimonial';

export default Testimonial;
