import * as React from 'react';
import styled from 'styled-components';
import Testimonial, { ITestimonialData } from './Testimonial';
import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';
import { shuffleArray } from '../../utils/array';

export interface ITestimonialGridProps {
  title: string;
  testimonials: ITestimonialData[];
}

export interface ITestimonialGridState {
  highlightedIndex: number;
  testimonials: ITestimonialData[];
}

const Title = styled.h2`
  margin: 10rem auto -3rem;
`;

const Wrapper = styled.section`
  margin: 4rem auto;
  @media ${medias.tabletMin} {
    max-width: ${theme.lgContainerWidth};
    margin: 8rem auto;
    display: grid;
    /* grid-auto-columns: minmax(280px, 400px); */
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 5%;
    justify-content: center;
  }
`;

export class TestimonialGrid extends React.Component<
  ITestimonialGridProps,
  ITestimonialGridState
> {
  constructor(props: ITestimonialGridProps) {
    super(props);
    this.state = {
      highlightedIndex: 1,
      testimonials: shuffleArray(props.testimonials).slice(0, 3),
    };
  }

  changeActive = (i: number) => {
    this.setState({ highlightedIndex: i });
  };

  render() {
    return (
      <>
        <Title className="text_center">{this.props.title}</Title>
        <Wrapper>
          {this.state.testimonials.map((testimonial, i) => (
            <Testimonial
              key={testimonial.name}
              {...testimonial}
              index={i}
              isHighlighted={i === this.state.highlightedIndex}
              changeActive={this.changeActive}
            />
          ))}
        </Wrapper>
      </>
    );
  }
}

export default TestimonialGrid;
