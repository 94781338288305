import * as React from 'react';
import styled from 'styled-components';
import { IImageField } from '../../../utils/types';
import GatsbyImage from 'gatsby-image';
import { medias } from '../../../styles/helpers/mediaQueries';
import theme from '../../../styles/theme';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface IHomePartnersProps {
  title: string;
  subtitle?: string;
  partners: Array<{ title: string; image: IImageField }>;
}

const Wrapper = styled.section`
  max-width: ${theme.lgContainerWidth};
  margin: 5rem auto;
  text-align: center;
  padding: 2rem 0.5rem 2.5rem;
  border: 0 solid rgba(255, 255, 255, 0.3);
  border-width: 1px 0 1px 0;
  h2 {
    margin: 0;
  }
  h3 {
    font-size: 1rem;
    font-weight: 300;
    margin: 0.5rem 0 1.5rem;
  }
`;

const PartnersWrapper = styled.div`
  display: block;
  position: relative;
  .slick-track {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  margin: 1rem 1rem 0;
  max-width: 200px;
  .gatsby-image-wrapper,
  img {
    max-height: 40px;
    object-fit: contain !important;
    @media ${medias.phoneMax} {
      max-width: 130px;
    }
  }
  @media ${medias.largeMin} {
    margin: 1rem 3rem 0;
  }
`;

const settings = {
  speed: 5000,
  autoplay: true,
  swipe: false,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: 'linear',
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: false,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const HomePartners: React.SFC<IHomePartnersProps> = props => {
  return (
    <Wrapper>
      <h2>{props.title}</h2>
      {props.subtitle ? <h3>{props.subtitle}</h3> : null}
      <PartnersWrapper>
        <Slider {...settings}>
          {props.partners.map(p => (
            <ImageWrapper key={p.title}>
              {p.image && p.image.asset && p.image.asset.fluid ? (
                <GatsbyImage
                  fluid={p.image.asset.fluid}
                  alt={`Logo de ${p.title}`}
                />
              ) : (
                <img src={p.image.imageUrl} alt={`Logo de ${p.title}`} />
              )}
            </ImageWrapper>
          ))}
        </Slider>
      </PartnersWrapper>
    </Wrapper>
  );
};

HomePartners.displayName = 'HomePartners';

export default HomePartners;
