import * as React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { IImageField } from '../../../utils/types';
import theme from '../../../styles/theme';
import { medias } from '../../../styles/helpers/mediaQueries';

export interface ICommunityMemberProps {
  name: string;
  company: string;
  image: IImageField;
  logo: IImageField;
  isFocused?: boolean;
}

const ImageWrapper = styled.div`
  position: relative;
`;

const MemberImage = styled.div`
  transition: ${theme.transitions.default};
  transform-origin: 50% 100%;
  .gatsby-image-wrapper {
    width: 10em !important;
    height: 10em !important;
  }
  img,
  .gatsby-image-wrapper {
    user-drag: none;
    box-shadow: ${theme.boxShadow.two};
    border-radius: 50%;
  }
`;

const CompanyImage = styled.div`
  position: absolute;
  bottom: -0.3rem;
  right: -0.3rem;
  background: white;
  border-radius: 50%;
  box-shadow: ${theme.boxShadow.three};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${theme.transitions.default};
  width: 5em;
  height: 5em;
  .gatsby-image-wrapper,
  img {
    width: 102% !important;
    height: 102% !important;
    border-radius: 50%;
    user-drag: none;
  }
`;

const TitleWrapper = styled.div`
  width: 250px;
  position: absolute;
  left: 50%;
  bottom: -0.5rem;
  transform: translate(-50%, 100%);
  opacity: 0;
  transition: ${theme.transitions.fast};
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  h3 {
    font-size: inherit;
    margin: 0 0 0.5rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
  font-size: 10px;
  padding: 0 1rem 0;
  text-align: center;
  outline: none;
  :hover {
    font-size: 15px;
    ${TitleWrapper} {
      opacity: 1;
      transition: ${theme.transitions.default} 0.2s;
    }
  }
  @media ${medias.tabletMax} {
    :focus {
      font-size: 15px;
      ${TitleWrapper} {
        opacity: 1;
        transition: ${theme.transitions.default} 0.2s;
      }
    }
  }
`;

export const CommunityMember: React.SFC<ICommunityMemberProps> = props => {
  const names = props.name.split(' ');
  return (
    <Wrapper tabIndex={0}>
      <ImageWrapper>
        {props.image && props.image.asset && props.image.asset.fixed && (
          <MemberImage>
            <GatsbyImage
              alt={`Foto de ${props.name}`}
              fixed={props.image.asset.fixed}
            />
          </MemberImage>
        )}
        {props.logo && props.logo.asset && props.logo.asset.fixed && (
          <CompanyImage>
            <GatsbyImage
              alt={`Foto da empresa ${props.name}`}
              fixed={props.logo.asset.fixed}
            />
          </CompanyImage>
        )}
      </ImageWrapper>
      <TitleWrapper>
        <h3>
          {names[0]}
          {names[1] !== undefined ? ` ${names[names.length - 1]}` : ''}
        </h3>
        {props.company}
      </TitleWrapper>
    </Wrapper>
  );
};

CommunityMember.displayName = 'CommunityMember';

export default CommunityMember;
