import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { IFileQueryData } from '../../../utils/types';
import GatsbyImage from 'gatsby-image';
import MapCard from './MapCard';
import { medias, breakpoints } from '../../../styles/helpers/mediaQueries';

export interface IHomeMapProps {
  mediumPosts: number;
  mediumClaps: number;
  podcastDownloads: number;
  podcastEpisodes: number;
  slackUsers: number;
  slackMessages: number;
  totalMembers: number;
}

const Wrapper = styled.section`
  max-width: 800px;
  margin: 6rem auto;
`;
export const growAnim = keyframes`
  0% {
    filter: saturate(1);
  }
  100% {
    filter: saturate(1.5);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin: 0.35rem 0 -5vh;
  div,
  img {
    max-width: 700px;
    max-height: 80vh;
  }
  img {
    animation: ${growAnim} 5s alternate infinite;
    object-fit: contain !important;
  }
  @media ${medias.tabletMin} {
    margin: -5rem 0 calc(-6rem - 8vh);
  }
  @media ${medias.largeMin} {
    margin: -5rem 0 calc(-10rem - 8vh);
  }
  @media (max-height: 700px) and (min-width: ${breakpoints.tablet}px) {
    margin: -5rem 0 calc(-5rem - 2vh);
  }
`;

const Title = styled.h2`
  position: absolute;
  text-align: center;
  top: 15%;
  left: 37.5%;
  z-index: 1;
  font-size: calc(15px + 10vw);
  line-height: 1;
  span {
    line-height: 1.3;
    font-size: .35em;
    display: block;
    margin: .35em 0 0 auto;
    max-width: 12ch;
      text-align: right;
  }
  @media ${medias.largeMin} {
    font-size: calc(40px + 5vw);
    span {
      margin: .15em 0 0 auto;
    }
  }
  @media ${medias.laptopMin} {
    font-size: 95px;
  }
  }
`;

const CardWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div:last-of-type {
    align-self: flex-end;
    margin-top: 1rem;
  }
  @media ${medias.tabletMin} {
    > div:last-of-type {
      margin-top: -1rem;
    }
  }
`;

export const HomeMap: React.SFC<IHomeMapProps> = props => {
  const stats = {
    email: [
      {
        number: '14.676+',
        title: 'inscritos',
      },
      {
        number: '306+',
        title: 'newsletters',
      },
    ],
    slack: [
      {
        number: `${(props.slackUsers - 1).toLocaleString('pr')}+`,
        title: 'membros',
      },
      {
        number: `${(props.slackMessages - 1).toLocaleString('pr')}+`,
        title: 'mensagens',
      },
    ],
    podcast: [
      {
        number: `${props.podcastEpisodes.toLocaleString('pr')}`,
        title: 'episódios',
      },
      {
        number: `${(props.podcastDownloads - 1).toLocaleString('pr')}+`,
        title: 'downloads mensais',
      },
    ],
    medium: [
      {
        number: `${props.mediumPosts.toLocaleString('pr')}`,
        title: 'posts',
      },
      {
        number: `${(props.mediumClaps - 1).toLocaleString('pr')}+`,
        title: 'curtidas',
      },
    ],
  };
  return (
    <Wrapper>
      <CardWrapper>
        <MapCard service="email" stats={stats.email} />
        <MapCard service="slack" stats={stats.slack} />
      </CardWrapper>
      <StaticQuery
        query={graphql`
          query mapQuery {
            file(relativePath: { eq: "mapa.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={({ file }: IFileQueryData) => (
          <ImageWrapper>
            <Title>
              {props.totalMembers.toLocaleString('pr')}+{' '}
              <span>Data Hackers no Brasil!</span>
            </Title>
            <GatsbyImage
              fluid={file.childImageSharp.fluid}
              placeholderStyle={{ display: 'none' }}
              aria-hidden={true}
            />
          </ImageWrapper>
        )}
      />
      <CardWrapper>
        <MapCard service="podcast" stats={stats.podcast} />
        <MapCard service="medium" stats={stats.medium} />
      </CardWrapper>
    </Wrapper>
  );
};

HomeMap.displayName = 'HomeMap';

export default HomeMap;
