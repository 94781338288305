import * as React from 'react';
import { graphql } from 'gatsby';

import { BasicLayout } from '../../layouts/BasicLayout';
import { PageMeta, IPageMeta } from '../../components/PageMeta';
import { IHomeBenefitsProps } from './components/HomeBenefits';
import { ITitleSubtitle } from '../../utils/types';
import Slack from '../../components/Slack/SlackWrapper';
import HomeHero, { IHomeHeroProps } from './components/HomeHero';
import TestimonialGrid from '../../components/Testimonials/TestimonialGrid';
import PostPreview, { IPostPreviewProps } from '../PostList/PostPreview';
import CTA from '../../components/CTA';
import HomeMap from './components/HomeMap';
import Button from '../../components/Button';
import SocialLinks from '../../components/SocialLinks';
import { PostWrapper } from '../../components/listPages/reusableComponents';
import HomePartners, { IHomePartnersProps } from './components/HomePartners';
import { ITestimonialData } from '../../components/Testimonials/Testimonial';
import { IPodcastPreviewData } from '../../components/Podcast/PodcastPreview';
import HomePodcast, { IHomePodcastData } from './components/HomePodcast';
import { ICommunityMemberProps } from './components/CommunityMember';
import HomeCommunity from './components/HomeCommunity';

interface IHomeDataProps {
  meta: IPageMeta;
  hero: IHomeHeroProps;
  partners: IHomePartnersProps;
  benefits: IHomeBenefitsProps;
  communityTitle: string;
  testimonialsTitle: string;
  blogIntro: string;
  podcast: IHomePodcastData;
  slackIntro: string;
  lastCTA: ITitleSubtitle;
}

export interface ICommunityData {
  totalMembers: number;
  newsSubs: number;
  podcastDownloads: number;
  slackMsg: number;
  slackUsers: number;
}

export interface IHomeTemplateProps {
  pageContext: {
    totalClapCount: number;
    communityMembers: ICommunityMemberProps[];
  };
  data: {
    home: IHomeDataProps;
    testimonials: {
      nodes: ITestimonialData[];
    };
    podcasts: {
      totalCount: number;
      nodes: IPodcastPreviewData[];
    };
    posts: {
      totalCount: number;
      nodes: IPostPreviewProps[];
    };
    news: {
      totalCount: number;
    };
    communityData: ICommunityData;
  };
}

export const HomeTemplate: React.SFC<IHomeTemplateProps> = props => {
  const { home, podcasts, posts, testimonials, communityData } = props.data;
  return (
    <BasicLayout shouldStartTransparent={true}>
      <PageMeta title="Página Inicial" {...home.meta} />
      <HomeHero {...home.hero} />
      <HomeMap
        podcastDownloads={communityData.podcastDownloads}
        podcastEpisodes={podcasts.totalCount}
        slackUsers={communityData.slackUsers}
        slackMessages={communityData.slackMsg}
        mediumPosts={posts.totalCount}
        mediumClaps={props.pageContext.totalClapCount}
        totalMembers={communityData.totalMembers}
      />
      <HomeCommunity
        members={props.pageContext.communityMembers}
        title={home.communityTitle}
      />
      <HomePartners {...home.partners} />
      <TestimonialGrid
        testimonials={testimonials.nodes}
        title={home.testimonialsTitle}
      />
      <h2 className="text_center">{home.slackIntro}</h2>
      <Slack includeCTA={true} />
      <HomePodcast {...home.podcast} podcasts={podcasts.nodes.slice(0, 1)} />
      <PostWrapper typeof="ItemList">
        <h2 style={{ flexBasis: '100%', textAlign: 'center' }}>
          {home.blogIntro}
        </h2>
        {posts.nodes.map((p, i) => (
          <PostPreview key={p.title} {...p} index={i} />
        ))}
      </PostWrapper>
      <p className="text_center" style={{ margin: '1rem auto 8rem' }}>
        <Button url="/blog" isSecondary={true}>
          Veja todos os artigos
        </Button>
      </p>
      <CTA title={home.lastCTA.title} subtitle={home.lastCTA.subtitle} />
      <SocialLinks />
    </BasicLayout>
  );
};

HomeTemplate.displayName = 'HomeTemplate';

export default HomeTemplate;

export const pageQuery = graphql`
  query homeQuery {
    home: sanityHome(_id: { eq: "homeId" }) {
      meta {
        seoTitle
        seoDescription
        ogImage {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      hero {
        subtitle
        title
      }
      partners {
        title
        subtitle
        partners {
          title
          image {
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
      communityTitle
      testimonialsTitle
      blogIntro
      slackIntro
      podcast {
        btnText
        subtitle
        title
      }
      lastCTA {
        subtitle
        title
      }
    }
    testimonials: allSanityTestimonial {
      nodes {
        body
        name
        title
        image {
          asset {
            fluid(maxWidth: 70) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
    podcasts: allAnchorEpisode(
      limit: 2
      sort: { fields: isoDate, order: DESC }
    ) {
      totalCount
      nodes {
        title
        isoDate
        contentSnippet
        enclosure {
          url
        }
      }
    }
    posts: allSanityMediumPost(
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      nodes {
        title
        url
        subtitle
        totalClapCount
        previewImageId
        authorName
        author {
          id
          name
          imageId
          username
        }
      }
    }
    communityData: sanityData {
      totalMembers
      newsSubs
      podcastDownloads
      slackMsg
      slackUsers
    }
  }
`;
