import * as React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import CommunityMember, { ICommunityMemberProps } from './CommunityMember';
import { shuffleArray } from '../../../utils/array';
import { medias } from '../../../styles/helpers/mediaQueries';
import { isLargeScreen } from '../../../utils/dom';

export interface IHomeCommunityProps {
  members: ICommunityMemberProps[];
  title: string;
}

export interface IHomeCommunityState {
  members: ICommunityMemberProps[];
}

const Title = styled.h2`
  margin: 10rem auto -3rem;
`;

const Wrapper = styled.div`
  position: relative;
  height: 300px;
  margin: 1rem -1rem;
  overflow-x: auto;
  overflow-y: visible;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${medias.largeMin} {
    overflow-x: hidden;
  }
`;

const Scroller = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  @media ${medias.largeMin} {
    justify-content: center;
  }
`;

export class HomeCommunity extends React.Component<
  IHomeCommunityProps,
  IHomeCommunityState
> {
  constructor(props: IHomeCommunityProps) {
    super(props);
    this.state = {
      members: shuffleArray(props.members),
    };
  }

  componentDidMount() {
    if (isLargeScreen) {
      // Set the maximum width to avoid displaying too many people
      const MAX_CONTAINER_WIDTH = 1400;
      const maxMembersNum = Math.floor(
        Math.min(window.innerWidth, MAX_CONTAINER_WIDTH) / 150
      );
      this.setState(prev => ({
        members: prev.members.slice(0, maxMembersNum),
      }));
    }
  }
  render() {
    const { props, state } = this;
    return (
      <>
        <Title className="text_center">{props.title}</Title>
        <Wrapper>
          <LazyLoad once={true}>
            <Scroller>
              {state.members.map(member => (
                <CommunityMember key={member.name} {...member} />
              ))}
            </Scroller>
          </LazyLoad>
        </Wrapper>
      </>
    );
  }
}

export default HomeCommunity;
