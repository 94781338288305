import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import MailIcon from '../../../components/icons/MailIcon';
import LogoSlack from '../../../components/icons/LogoSlack';
import LogoMedium from '../../../components/icons/LogoMedium';
import LogoSpotify from '../../../components/icons/LogoSpotify';
import { IStringNumberTitle } from '../../../utils/types';
import theme from '../../../styles/theme';

export type TService = 'email' | 'slack' | 'medium' | 'podcast';

export interface IMapCardProps {
  service: TService;
  stats: IStringNumberTitle[];
}

const Icons = {
  email: <MailIcon />,
  slack: <LogoSlack />,
  medium: <LogoMedium />,
  podcast: <LogoSpotify />,
};

export const floatAnim = keyframes`
  0% {
    transform: translateY(0);
    /* transform: translateY(0) scale(1); */
  }
  100% {
    transform: translateY(15%);
    /* transform: translateY(5%) scale(1.05); */
  }
`;

const Wrapper = styled.div`
  background: white;
  box-shadow: ${theme.boxShadow.five};
  border-radius: 5px;
  padding: 0.35em 0.75em 0.35em 0.5em;
  display: inline-flex;
  align-items: center;
  min-width: 27ch;
  max-width: 30ch;
  animation: ${floatAnim} 5s ease-out alternate infinite;
  :nth-of-type(1) {
    animation-delay: 4s;
  }
`;

const IconWrapper = styled.div`
  width: 2.5em;
  margin-right: 1em;
  > span {
    display: inline;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  font-size: 0.9em;
  p {
    margin: 0;
    color: ${theme.colors.bg};
    :first-of-type {
      margin-bottom: 0.15em;
    }
  }
`;

export const MapCard: React.SFC<IMapCardProps> = props => {
  return (
    <Wrapper>
      <IconWrapper>{Icons[props.service]}</IconWrapper>
      <ContentWrapper>
        {props.stats.map(s => (
          <p key={s.number}>
            <b>{s.number}</b> {s.title}
          </p>
        ))}
      </ContentWrapper>
    </Wrapper>
  );
};

MapCard.displayName = 'MapCard';

export default MapCard;
