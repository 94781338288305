import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import CTA from '../../../components/CTA';
import { graphql, StaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import LogoHorizontal from '../../../components/icons/LogoHorizontal';
import { medias } from '../../../styles/helpers/mediaQueries';
import { IFileQueryData } from '../../../utils/types';

export interface IHomeHeroProps {
  title: string;
  subtitle: string;
}

const Wrapper = styled.section`
  /* min-height: 100vh; */
  text-align: center;
  position: relative;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 650px;
  margin: 0 auto;
  padding: 8vh 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  > span {
    max-width: 70vw;
  }
  aside {
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  margin: 0 0 0.5rem;
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  line-height: 1.65;
  font-weight: 300;
  max-width: 20em;
  margin: 0 auto;
`;

const spinAnimation = keyframes`
  from {
    transform: translateY(-28%) rotate(0);
  } to {
    transform: translateY(-28%) rotate(360deg);
  }
`;

const ImageWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: calc(100% + 2rem);
  height: 100%;
  img {
    object-fit: contain !important;
    /* object-position: center top !important; */
    animation: 520s ${spinAnimation} cubic-bezier(0.25, -0.64, 0.55, 1.84)
      infinite;
  }
  .gatsby-image-wrapper {
    height: 135%;
    > div {
      display: none;
    }
    @media ${medias.largeMax} {
      width: 130%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const Spacer = styled.div`
  @media ${medias.tabletMin} {
    display: none;
  }
  flex: 1;
`;

export const HomeHero: React.SFC<IHomeHeroProps> = props => {
  return (
    <Wrapper>
      <StaticQuery
        query={graphql`
          query heroQuery {
            file(relativePath: { eq: "fundo-hero.jpg" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={({ file }: IFileQueryData) => (
          <ImageWrapper aria-hidden={true}>
            <GatsbyImage fluid={file.childImageSharp.fluid} />
          </ImageWrapper>
        )}
      />
      `
      <ContentWrapper>
        <LogoHorizontal margin="0 0 calc(1.5rem + 3vh)" width="330px" />
        <Title>{props.title}</Title>
        <Subtitle>{props.subtitle}</Subtitle>
        <Spacer />
        <CTA subtitle="Inscreva-se na newsletter quinzenal" />
      </ContentWrapper>
    </Wrapper>
  );
};

HomeHero.displayName = 'HomeHero';

export default HomeHero;
